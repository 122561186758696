.navBarContainer {
  width: calc(100vw - 4rem);
  height: 5vh;
  display: flex;
  align-items: center;
  padding: 1rem 2rem;
  border-bottom: 1px solid #e3e0dd;
  gap: 60px;
}

.redirectLinks {
  display: flex;
  align-items: center;
  gap: 30px;
}

.redirectLinks a {
  text-decoration: none;
  color: #262626;
  font-size: 14px;
  font-weight: 500;
}
