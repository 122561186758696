.errorPage {
  font-family: "Raleway", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
}

.errorPageEmoji {
  font-size: 100px;
  line-height: 0;
}
