.addEmailContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 2rem;
  width: 60%;
}

.addTeamInputContainer {
  display: flex;
  gap: 10px;
}

.addTeamInput {
  width: 90%;
  padding: 0.5rem;
  border: 1px solid #e3e0dd;
  border-radius: 10px;
  font-family: "Raleway", sans-serif;
}

.deleteTeamMemberButton {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.addEmailButton {
  padding: 0.5rem;
  border: 1px solid #e3e0dd;
  background-color: transparent;
  cursor: pointer;
  border-radius: 10px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}

.inviteEmailsButton {
  width: 30%;
  align-self: flex-end;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  color: #fff;
  background-color: #fb046e;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  padding: 0.5rem 1rem;
}
