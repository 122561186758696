

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    font-family: "Raleway", sans-serif;
}

.image {
    max-width: 30%;
}

.textBox {
    text-align: center;
}

.button {
    background: #FB046E;
    border-radius: 8px;
    font-family: 'Raleway', "sans-serif";
    font-weight: 700;
    color: white;
    border: none;
    padding: 10px;
    width: 60%;
    margin: 12px auto;
    cursor: pointer;
}