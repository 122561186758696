
.welcomePageContainer {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.welcomeModal {
    width: 40%;
    /*height: 65%;*/
    box-shadow: 0px 5px 20px rgba(15, 15, 15, 0.08);
    display: flex;
    flex-direction: column;
}

.welcomeModalTop {
    background-color: #FDBD0C;
    display: flex;
    gap: 5%;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
}

.welcomeModalTop img {
    width: 40%;
}

.welcomeModalTextBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    padding: 4% 5% 1% 5%;
}

.welcomeModalTextBox h1 {
    margin: 6px 0px;
}

.welcomeModalTextBox p {
    margin: 9px auto;
}

.welcomeModalButton {
    background: #FB046E;
    border-radius: 4px;
    font-family: 'Raleway', "sans-serif";
    font-weight: 600;
    color: white;
    border: none;
    padding: 15px;
    width: 90%;
    margin: 14px auto;
    cursor: pointer;
    font-size: 16px;
}

.welcomeModalButton:hover {
    background: #de0060;
}

@media (max-width: 900px) {
    .welcomeModal {
        width: 60%;
    }
}

@media (max-width: 600px) {
    .welcomeModal {
        width: 95%;
        /*height: 95%;*/
    }
}