
.ConnectorSearchBar {
  margin: 0% 20%; 
}

.ConnectorTiles {
  overflow-y: scroll;
  max-height: 50vh;
  margin: 2% 15%;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;

}

.Tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: white;
  box-shadow: 0px 5px 10px rgba(22,22,22,0.10);
  border-radius: 10px;
  padding: 5%;
  margin: 5% 5%;
  aspect-ratio: 1;
  cursor: pointer;
}

.Tile:hover {
  cursor: pointer;
  background-color: #f9f9f8;
  box-shadow: 0px 6px 10px rgba(22,22,22,0.12);
}

.Tile img {
  object-fit: contain;
  height: 50%;
  width: 50%;
}

h1 {
  margin-top: 0
}

.Tile h3 {
  font-size: 1rem;
  font-weight: 300;
}

p {
  color: #0F566C
}

a {
  margin: 0;
  color: #0F566C;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow:  inset 0 0 6px rgba(71, 71, 71, 0.2);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #7D7D7D;
  border-radius: 5px;
}

.CreateConnectorPortal .BackButton {
  position: absolute;
  left: 300px
}

.BackButton {
  position: absolute;
  left: 50px;
  top: 30px;
}

@media (max-width: 600px) {
  .Tile h3 {
    font-size: 0.8rem;
  }
}