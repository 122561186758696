/* CONNECTORS */

.tableButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.viewIcon {
  height: 20px;
}
