.CreateConnectorPage {
  background-color: #fff;
  padding: 0% 10%;
  height: 100%;
  position: relative;
}

.LimitConnectorPage {
  background-color: #fff;
  padding: 5% 10%;
  height: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.LimitConnectorPage a {
  text-decoration: none;
}

.CreateConnectorPortal {
  padding: 0%;
  min-height: 90vh;
}

.ConnectorSearchBar {
  margin: 0% 20%;
}

h1 {
  margin-top: 0;
}

h3 {
  font-size: 0.8rem;
  font-weight: 300;
}

.CreateConnectorPage p {
  color: #252525;
}

a {
  margin: 0;
  color: #252525;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(71, 71, 71, 0.2);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #7D7D7D;
  border-radius: 5px;
}

.CreateConnectorPortal .BackButton {
  position: absolute;
  left: 300px;
}

.BackButton {
  position: absolute;
  left: 50px;
  top: 30px;
}

.CredentialComponent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 5%;
}

.CredentialComponentWidgetOnly {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}

.widgetContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.credentialSeparator {
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 6px;
}

.credentialSeparator p {
  color: rgb(115, 115, 115);
}

.credentialSeparator div {
  border-top: 1px solid rgb(219, 219, 219);
  width: 100%;
}

.CredentialComponent p {
  text-align: left;
}

.CreateDashboard {
  margin: 0% 15%;
}

.CreateDashboardComponent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 60vh;
}

@media (max-width: 800px) {
  .BackButton {
    position: relative;
    align-self: flex-start;
    top: 0;
    left: 0;
  }
  .SkipButton {
    width: 50%;
  }
  .CreateDashboardComponent {
    margin-top: 20px;
  }
}

@media (max-width: 600px) {
  .ConnectorTiles {
    margin: 5% 5%;
    grid-template-columns: 50% 50%;
  }
  .SkipButton {
    width: 60%;
  }
}

@media (max-width: 1200px) and (min-width: 600px) {
  .ConnectorTiles {
    margin: 5% 5%;
    grid-template-columns: 33% 33% 33%;
  }
}
