.pageLayoutSection {
  display: flex;
}

/* LEFT PAGE SECTION */

.leftPageSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
  width: 240px;
  padding-left: 2rem;
  min-height: 80vh;
  max-height: 80vh;
}

.leftMenuHeader h2 {
  text-align: left;
  font-weight: 600;
  font-size: 22px;
}

.leftMenuLinks {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.leftMenuLink {
  text-decoration: none;
}

.leftMenuLinkContainer {
  display: flex;
  gap: 20px;
  align-items: center;
}

.leftMenuLinkIcon {
  height: 20px;
}

.leftMenuLinkText {
  font-weight: 500;
  font-size: 15px;
}

.leftMenuLinkContainer {
  padding: 0rem 0.5rem;
}

.leftMenuLinkContainer.active {
  background-color: #9bc1bc26;
  border-radius: 10px;
}

.logOutContainer {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

/* RIGHT SECTION */

.rightPageSection {
  width: calc(100% - 250px);
}

.rightPageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;
}

.pageTitle {
  font-size: 18px;
  font-weight: 500;
}

.CTAButton {
  background-color: #fb046e;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  padding: 0.6rem 1rem;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Raleway", sans-serif;
  color: #fff;
  box-shadow: 0px 5px 20px rgba(15, 15, 15, 0.1);
}

.CTAButtonPlusIcon {
  height: 15px;
  filter: invert(100%) sepia(17%) saturate(0%) hue-rotate(188deg)
    brightness(101%) contrast(101%);
}

.Components {
  width: calc(100% - 240px);
  margin-top: 2%;
  display: flex;
  flex-direction: column;
}
