.teamMembersPageChangeTeamBox {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 40px;
  width: calc(100% - 4rem);
  padding: 0 2rem;
}

.pageTitle.teamMembersChange {
  margin: 0;
  font-weight: 600;
  color: #252525;
  font-size: 16px;
}
