

.facebookButton {
    all: unset;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: #1877F2;
    padding: 0 6px;
    border-radius: 6px;
    cursor: pointer;
    min-width: 220px;
}

.facebookButton img {
    width: 24px;
    height: 24px;
}

.facebookButton p {
    color: #fff !important;
    font-weight: 600;
}

.googleButton {
    all: unset;
    cursor: pointer;
}

.googleButton img {
    max-height: 50px;
}

.tiktokButton {
    all: unset;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: #000000;
    padding: 0 6px;
    border-radius: 6px;
    cursor: pointer;
}

.tiktokButton img {
    width: 24px;
    height: 24px;
}

.tiktokButton p {
    color: #fff !important;;
    font-weight: 500;
}

.pinterestButton {
    all: unset;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: #E60023;
    padding: 0 6px;
    border-radius: 6px;
    cursor: pointer;
}

.pinterestButton img {
    width: 24px;
    height: 24px;
}

.pinterestButton p {
    color: #fff !important;
    font-weight: 600;
}

.linkedinButton {
    all: unset;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: #0474B3;
    padding: 0 6px;
    border-radius: 6px;
    cursor: pointer;
}

.linkedinButton img {
    width: 24px;
    height: 24px;
}

.linkedinButton p {
    color: #fff !important; 
    font-weight: 600;
}

.twitterButton {
    all: unset;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0 6px;
    border: solid #179CF0 1px;
    border-radius: 6px;
    cursor: pointer;
}

.twitterButton img {
    width: 24px;
    height: 24px;
}

.twitterButton p {
    color: #000 !important;
    font-weight: 500;
}