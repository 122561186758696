

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.banner {
    background: #FDBD0C;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 5% 0%;
}

.description {
    margin: 0 5% 5% 5%;
    font-family: "Raleway", sans-serif;
}
.exploreButton {
    width: 100%;
    background: #fb046e;
    border: none;
    border-radius: 3px;
    color: white;
    font-family: "Raleway", sans-serif;
    padding: 1% 0%;
    cursor: pointer;
}